.fud_main_content_wrapper {
    width: auto;
    .fud_main_content {
        width: inherit;
        margin: 50px 36px;
        gap: 36px;
        position: relative;
    }
    .button_wrapper_mobile, .mobile_notification_content, .mobile_next_step, .mobile_header {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .fud_main_content_wrapper {
        .fud_main_content {
            margin: 32px 24px;
        }
        .button_wrapper_mobile, .mobile_notification_content, .mobile_next_step, .mobile_header {
            display: block;
        }
        .mobile_notification_content {
            // margin-top: 11px;
            max-height: 48px;
            overflow: scroll;
            scroll-behavior: smooth !important;
        
        }
        .mobile_notification_content::-webkit-scrollbar {
            display: none !important;
            scroll-behavior: smooth !important;
        }
    }
}