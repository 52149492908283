.video_player_wrapper {
   width: 100%;
   display: flex;
   align-items: center;
   .video_player, .mobile_player, .webinar_player{
      border-radius: 20px;
      width: 100%;
   } 

   .video_player {
      height: 525px;
   }

   .mobile_player {
      height: 162px;  
   }

   .webinar_player {
      height: 266px;
   }


}

@media screen and (max-width: 800px) {
    .video_player_wrapper {
      video {
         border-radius: 12px;
         height: 100%;
        } 
     }
}