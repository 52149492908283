.fud_main_section_card_wrapper {
    .fud_main_section_card {
        width: 100%;
        min-height: fit-content;
        border-radius: 24px;
        background: var(--gs-learn-light-mode-grey-5, rgba(3, 10, 33, 0.05));
        .heading_wrapper {
            padding: 24px;
            gap: 8px;
            border-bottom: 1px solid var(--gs-learn-light-mode-grey-4, rgba(3, 10, 33, 0.10));
            align-items: center;
            img {
                max-height: 37px;
            }
        }
        .remove_border {
            border-bottom: unset !important;
        }
        .fud_main_section_content {
            padding: 24px;
            gap: 40px;
        }

        .fud_webinar_content {
            gap: 29px;
            padding: 24px;
            // .webinar_variant_video_card {
            //     div {
            //         height: 100% !important;
            //         img {
            //             border-radius: 20px;
            //             height: inherit !important;
            //         }
            //     }
            // }
        }
    }
}

@media screen and (max-width: 800px) {
    .fud_main_section_card_wrapper {
        .fud_main_section_card {
            border-radius: 16px;
            .heading_wrapper {
                padding: 20px 16px;
            }
            .fud_main_section_content {
                padding: 24px 16px;
            }
        }
    }
}