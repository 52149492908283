.join_whatsapp_wrapper {
    .glare_div {
        height: 100%;
        img {
            height: 100% !important;
        }
        animation: 1500ms carousell linear infinite;
        transform-style: preserve-3d;

        @keyframes carousell {
            0% {
                transform: translateX(-30%);
            }

            100% {
                transform: translateX(100%);
            }
        }
    }
    .join_whatsapp_card {
        justify-content: space-between;
        border-radius: 12px;
        border: 1px solid var(--gs-learn-mockingbird, #030A21);
        padding: 10px 8px;
        align-items: center;
        .left_content_wrapper {
            gap: 7.5px;
            align-items: center;
        }
    }
}