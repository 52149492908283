.main_content_wrapper {
    .main_content {
        gap: 40px;
        .middle_content_elements {
            gap: 40px;
        }
    }
    .mobile_next_step {
        img {
            width: 100%;
        }
    }
}