.phone_component_wrapper {
    height: 100%;
    .phone_component_card {

        border-radius: 20px;
        border: 1px solid var(--gs-learn-light-mode-grey-2, rgba(3, 10, 33, 0.30));
        background: var(--gs-learn-surface, #FFF);
        padding: 16px;
        gap: 13px;
        height: 100%;

        .top_card {
            border-radius: 12px;
            border: 1px solid var(--gs-learn-light-mode-grey-5, rgba(3, 10, 33, 0.05));
            background: var(--gs-learn-light-mode-grey-4, rgba(3, 10, 33, 0.10));
            gap: 40px;
            padding: 20px 20px 0px 24px;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            overflow: hidden;
            height: 100%;
            img {
                position: absolute;
                top: 20px;
                right: 20px;
                width: 151px;
                height: 152px;
                border-top-left-radius: 22px;
                border-top-right-radius: 22px;
            }
            span{
                width: 45%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .phone_component_wrapper {
        .phone_component_card {
            padding: unset;
            border: unset;
            border-radius: unset;
            background: transparent;

            .top_card {
                display: none;
            }

            .bottom_card {
                display: flex;
                flex-direction: column;
                gap: 12px;
            }
        }
    }
}