.fud_next_step_wrapper {
    min-width: 100%;
    height: 116px;
    max-width: 316px;
    margin-bottom: 180px;

    img {
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

}

@media screen and (max-width: 800px) {
    .fud_next_step_wrapper {
        margin-bottom: unset;
    }
}